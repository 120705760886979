<h3>Co-Products</h3>
<ng-container *ngFor="let coproduct of coProducts">
  <tr>
    <td class="label" colspan="2">
      <app-copiable-id
        size="small"
        [copiableId]="coproduct.id"
        tooltipPosition="right"
        ><a
          *ngIf="coproduct.id; else onlyLabel"
          routerLinkActive="active"
          [routerLink]="['/datasets', coproduct.id]"
          >{{ coproduct.name }}</a
        ></app-copiable-id
      >
      <ng-template #onlyLabel
        >{{ coproduct.name }} (no background data available)
      </ng-template>
    </td>
    <td>
      <ng-container *ngIf="coproduct.allocation"
        >{{ coproduct.allocation | number : "1.1-2" }}%
      </ng-container>
    </td>
    <td>{{ coproduct.amount }}</td>
    <td>{{ coproduct.unit | formatUnit }}</td>
    <td *ngIf="methodIndicator" [attr.colspan]="nbColumns.contribution"></td>
    <td
      exchangeCommentToggle
      [id]="coproduct.id"
      [comment]="coproduct.comment"
      [isExpanded]="expandedCommentId === coproduct.id"
      (expandedCommentIdChange)="onExpandedCommentIdChange($event)"
    ></td>
    <td></td>
  </tr>
  <tr
    *ngIf="expandedCommentId === coproduct.id"
    exchangeComment
    [comment]="coproduct.comment"
    [totalNbColumns]="nbColumns.total"
  ></tr>
</ng-container>
