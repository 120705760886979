import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import { Dataset } from '@app/modules/dataset/models/dataset.model';
import {
  Classification,
  GroupedClassification,
  groupClassifications,
} from '@app/modules/reference-data/modules/classification/models/classification.model';

@Component({
  selector: 'app-info-tab[dataset]',
  templateUrl: './info-tab.component.html',
  styleUrl: './info-tab.component.scss',
})
export class InfoTabComponent implements OnChanges {
  @Input()
  dataset!: Dataset;
  groupedClassifications: GroupedClassification[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if ('dataset' in changes) {
      this.groupedClassifications = this.getGroupedClassifications(
        this.dataset.description.classifications,
      );
    }
  }

  private getGroupedClassifications(
    classifications: Classification[] | undefined,
  ): GroupedClassification[] {
    if (classifications === undefined) {
      return [];
    }
    const groupedClassifications: GroupedClassification[] = [];

    const classificationMap = groupClassifications(classifications);
    for (const [system, classifications] of classificationMap) {
      groupedClassifications.push({
        system,
        values:
          classifications
            .map((c) => c.code + ' - ' + c.description)
            .sort()
            .join(', ') ?? '',
      });
    }

    return groupedClassifications.sort((a, b) =>
      a.system > b.system ? 1 : -1,
    );
  }
}
