<mat-form-field>
  <mat-label>Contribution type</mat-label>
  <mat-select
    [formControl]="contributionTypesFilterCtrl"
    [multiple]="false"
    (selectionChange)="onValueChange()"
  >
    <mat-option *ngFor="let option of options" [value]="option"
      >{{ option | contributionTypeLabel }}
    </mat-option>
  </mat-select>
</mat-form-field>
