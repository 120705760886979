import { Injectable } from '@angular/core';
import { DatasetDto } from '@app/api/__generated__/model/datasetDto';
import { HistoryDto } from '@app/api/__generated__/model/historyDto';
import { AbstractMapper } from '@app/modules/core/mappers/abstract-mapper';
import { IndicatorMapperService } from '@app/modules/dataset/mappers/indicator/indicator-mapper.service';
import { ExchangeMapperService } from '@app/modules/dataset/modules/exchange/mappers/exchange-mapper.service';
import { ParentMapperService } from '@app/modules/dataset/modules/parent/components/mappers/parent-mapper.service';
import { CoProduct, Dataset } from '../../models/dataset.model';
import {History, HistoryDetail, HistoryStatus} from '../../modules/history/models/history.models';
import { AssessmentMapperService } from '../assessment/assessment-mapper.service';
import { DatasetDescriptionMapperService } from '../dataset-description/dataset-description-mapper.service';
import { EmbeddedProductMapperService } from '@app/modules/dataset/mappers/embedded-product/embedded-product-mapper.service';
import {ContributionMapperService} from "@app/modules/dataset/modules/contribution/mappers/contribution-mapper.service";
import {HistoryDetailDto} from "@app/api/__generated__/model/historyDetailDto";

@Injectable({
  providedIn: 'root',
})
export class DatasetMapperService extends AbstractMapper<DatasetDto, Dataset> {
  constructor(
    private readonly ddMapper: DatasetDescriptionMapperService,
    private readonly indMapper: IndicatorMapperService,
    private readonly exchangeMapper: ExchangeMapperService,
    private readonly parentMapper: ParentMapperService,
    private readonly contributionMapper: ContributionMapperService,
    private readonly assessmentGroupMapper: AssessmentMapperService,
    private readonly embeddedProductMapper: EmbeddedProductMapperService
  ) {
    super();
  }

  from(dto: DatasetDto): Dataset {
    const {
      id,
      description,
      impactIndicatorSamples,
      coProducts,
      contributions,
      embeddedProducts,
    } = dto;
    return {
      id,
      description: this.ddMapper.from(description),
      impactIndicatorSamples: impactIndicatorSamples?.map((sample) =>
        this.indMapper.mapIndicatorSample(sample)
      ),
      assessmentGroups: this.assessmentGroupMapper.asGroups(contributions),
      exchanges: this.exchangeMapper.from(dto),
      coProducts: coProducts.map((product) => product as CoProduct),
      parents: this.parentMapper.from(dto.parents),
      contributions: this.contributionMapper.fromList(contributions),
      flattenContributions:
        this.contributionMapper.flattenFromList(contributions),
      embeddedProducts: this.embeddedProductMapper.fromList(embeddedProducts),
      history: this.mapHistory(dto.history),
      isFlag: dto.isFlag,
      isLuc: dto.isLuc,
    };
  }

  private mapHistory(dto: HistoryDto): History {
    return new History(
      dto.parentDatabase,
      dto.parents.map(d => this.mapHistoryDeTails(d)),
      dto.childDatabase,
      dto.children.map(d => this.mapHistoryDeTails(d)),
      dto.equivalentVersions.map(d => this.mapHistoryDeTails(d)),
    );
  }

  private mapHistoryDeTails(dto: HistoryDetailDto) : HistoryDetail {
    return {
      database: dto.database,
      product: dto.product,
      productId: dto.productId,
      impactIndicatorSamples: dto.impactIndicatorSamples,
      status: dto.status as HistoryStatus,
    }
  }
}
