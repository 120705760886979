<app-group-filter
  class="filter"
  [options]="filteredOptionGroups"
  [value]="selection.group"
  (valueChange)="onIndicatorGroupChange($event)"
></app-group-filter>
<app-indicator-filter
  class="filter"
  [options]="optionIndicators$ | async"
  [value]="selection.indicator"
  (valueChange)="onIndicatorChange($event)"
></app-indicator-filter>
<app-contribution-type-filter
  class="filter"
  [options]="optionContributionsType$ | async"
  [value]="selection.contributionType"
  (valueChange)="onContributionTypeChange($event)"
></app-contribution-type-filter>
<app-contribution-name-filter
  class="filter narrow"
  [options]="optionContributions$ | async"
  [value]="selection.contribution"
  (valueChange)="onContributionNameChange($event)"
></app-contribution-name-filter>
