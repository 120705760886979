import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ContributionTypeName } from '@app/modules/dataset/modules/contribution/model/contribution.model';
import { MethodIndicator } from '@app/modules/reference-data/modules/method/models/method.model';
import { TechnoExchange } from '../../../../models/exchange.model';
import { ExchangeWithContributionMapperService } from '@app/modules/dataset/modules/exchange/mappers/exchange-with-contribution-mapper.service';
import { nbColumns } from '../exchange-table-section/exchange-table.utils';

@Component({
  selector: '[exchangeTechnosphere]',
  templateUrl: './exchange-details-technosphere.component.html',
})
export class ExchangeDetailsTechnosphereComponent implements OnChanges {
  @Input()
  exchanges!: TechnoExchange[];

  @Input()
  totalDirectContribution: number | undefined;

  @Input()
  methodIndicator: MethodIndicator | undefined;

  @Input()
  contributionType: ContributionTypeName | undefined;

  @Input()
  showPercentages = false;

  @Input()
  expandedCommentId: string | undefined;

  @Output()
  expandedCommentIdChange = new EventEmitter<string>();

  displayableExchanges!: TechnoExchange[];

  nbColumns = {
    total: 0,
  };

  constructor(
    private readonly exchangeWithContributionMapperService: ExchangeWithContributionMapperService<TechnoExchange>
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      'exchanges' in changes ||
      'methodIndicator' in changes ||
      'contributionType' in changes
    ) {
      this.displayableExchanges =
        this.exchangeWithContributionMapperService.from(
          this.exchanges,
          this.methodIndicator,
          this.contributionType
        );
      this.nbColumns = nbColumns(this.contributionType);
    }
  }

  onExpandedCommentIdChange(expandedCommentId: string): void {
    this.expandedCommentIdChange.emit(expandedCommentId);
  }
}
