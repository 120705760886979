<app-dataset-warning
  [datasetDescription]="dataset.description"
></app-dataset-warning>
<h2>Contribution analysis</h2>
<div>
  <app-contribution-filters [dataset]="dataset" (contributionChanged)="onContributionChange($event)"/>
  <button (click)="onExport()" color="primary" mat-stroked-button>
    Export contribution
  </button>
</div>
<mat-slide-toggle
  color="primary"
  *ngIf="hasContribution"
  (change)="onShowPercentagesChange($event.checked)"
  >Show percentages
</mat-slide-toggle>
<app-exchange-table
  [dataset]="dataset"
  [methodIndicator]="selection.indicator ?? undefined"
  [contributionType]="selection.contributionType ?? undefined"
  [showPercentages]="showPercentages"
></app-exchange-table>
<ng-container
  *ngIf="
    selection.indicator !== null &&
    selection.contribution !== null &&
    hasContribution
  "
>
  <h2>Main contribution flows (above 1%)</h2>
  <app-sankey
    [datasetId]="dataset.id"
    [referenceProduct]="dataset.exchanges.referenceProduct.name"
    [indicator]="selection.indicator"
    [contributionType]="selection.contribution"
  ></app-sankey>
</ng-container>
