<h2>
  Current database version
  <span class="database-header"
    >({{ dataset.description.database.displayName }})</span
  >
</h2>
<table>
  <tr>
    <td class="label">Dataset</td>
    <td>
      {{ dataset.exchanges.referenceProduct.name }}
      <app-impact-factor-sample-list
        [factorSamples]="dataset.impactIndicatorSamples"
      ></app-impact-factor-sample-list>
    </td>
  </tr>
  <tr *ngIf="hasNoParent">
    <td colspan="2" class="no-parent">
      This dataset has no parent as it was created in this current database
      version.
    </td>
  </tr>
  <tr *ngIf="hasNoChild">
    <td colspan="2" class="no-parent">
      This dataset has no child as it was removed in following database
      versions.
    </td>
  </tr>
</table>
