import {
  Component,
  EventEmitter,
  Input,
  OnDestroy, OnInit,
  Output,
} from '@angular/core';
import { Dataset } from '@app/modules/dataset/models/dataset.model';
import { AppState } from '@app/store';
import { Store } from '@ngrx/store';
import {combineLatest, startWith, Subject} from 'rxjs';
import { exportBasket } from '@app/modules/basket/store/dataset/basket-dataset.actions';
import {
  ContributionSelection, emptySelection, isCompleteSelection,
} from 'src/app/modules/dataset/modules/contribution-selection/model/contribution-selection.model';
import {takeUntil} from "rxjs/operators";
import {RumService} from "@app/modules/rum/rum.service";

@Component({
  selector: 'app-exchanges-tab',
  templateUrl: './exchange-tab.component.html',
  styleUrl: './exchange-tab.component.scss',
})
export class ExchangeTabComponent implements OnDestroy, OnInit {
  _dataset!: Dataset;

  @Input()
  set dataset(dataset: Dataset) {
    this._dataset = dataset;
    if(this._dataset?.id !== dataset.id) {
      this.datasetChange$.next(dataset);
    }
  }
  get dataset(): Dataset {
    return this._dataset;
  }

  @Output()
  contributionSelectionChange = new EventEmitter<ContributionSelection>();

  private datasetChange$ = new Subject<Dataset>();

  protected selection: ContributionSelection = emptySelection();

  get hasContribution(): boolean {
    return this.selection?.contribution !== null;
  }

  showPercentages = false;

  private onDestroy$ = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private rumService: RumService,
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.datasetChange$.pipe(startWith(this._dataset)),
      this.contributionSelectionChange
    ])
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(([dataset, selection]) => {
      if(isCompleteSelection(selection)) {
        this.rumService.triggerContributionsView(dataset, selection);
      }
    });
  }

  ngOnDestroy(): void {
    this.datasetChange$.complete();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onShowPercentagesChange(showPercentages: boolean): void {
    this.showPercentages = showPercentages;
  }

  onContributionChange(selection: ContributionSelection): void {
    this.selection = selection;
    this.contributionSelectionChange.emit(selection);
  }

  onExport(): void {
    this.store.dispatch(
      exportBasket({
        options: {
          datasetIds: [this.dataset.id],
          groups:
            this.selection.group !== null ? [this.selection.group.name] : [],
          withExchangeContribution: true,
          withObsoleteFlagContribution: true,
          withSplitGasContribution: true,
          withSplitActivityContribution: true,
          withEmbeddedProducts: true,
        },
      }),
    );
  }
}
