import { Injectable } from '@angular/core';
import { RefProductExchange } from '@app/modules/dataset/modules/exchange/models/exchange.model';
import { MethodIndicator } from '@app/modules/reference-data/modules/method/models/method.model';
import {
  Contribution,
  ContributionsTree,
  ContributionTypeName
} from "@app/modules/dataset/modules/contribution/model/contribution.model";
import {ContributionService} from "@app/modules/dataset/modules/contribution/services/contribution.service";


@Injectable({
  providedIn: 'root',
})
export class RefProductContributionsMapperService {
  constructor(private readonly contributionService: ContributionService) {}

  from(
    refProductExchange: RefProductExchange,
    methodIndicator: MethodIndicator | undefined,
    contributionType: ContributionTypeName | undefined
  ): Array<Array<Contribution>> {
    if (methodIndicator === undefined || contributionType === undefined) {
      return [];
    }
    return (ContributionsTree.get(contributionType) ?? []).map((nodes) => {
      return nodes.map((node) => {
        const nodeContribution = this.contributionService.findContributions(
          refProductExchange.flattenContributions,
          methodIndicator,
          { type: node.type, name: node.name }
        );
        return { ...node, amount: nodeContribution[0]?.amount };
      });
    });
  }
}
