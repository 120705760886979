import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { EmbeddedProduct } from '@app/modules/dataset/models/embedded.product.model';

@Pipe({
  name: 'displayEmbeddedProductLabel',
})
export class EmbeddedProductLabelPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) public locale: string) {}

  transform(ep: EmbeddedProduct): string {
    const embeddedProductTypeToLabel = {
      ELECTRICITY: 'Electricity',
      SOY: 'Soy',
      PALM: 'Palm',
    };
    return embeddedProductTypeToLabel[ep.type];
  }
}
