import { Injectable } from '@angular/core';
import { Exchange } from '@app/modules/dataset/modules/exchange/models/exchange.model';
import { MethodIndicator } from '@app/modules/reference-data/modules/method/models/method.model';
import {
  Contribution,
  contributionsTreeLeaves,
  ContributionTypeName
} from "@app/modules/dataset/modules/contribution/model/contribution.model";
import {ContributionService} from "@app/modules/dataset/modules/contribution/services/contribution.service";


@Injectable({
  providedIn: 'root',
})
export class ExchangeWithContributionMapperService<T extends Exchange> {
  constructor(private readonly contributionService: ContributionService) {}

  from(
    exchanges: T[],
    methodIndicator: MethodIndicator | undefined,
    contributionType: ContributionTypeName | undefined
  ): Array<T> {
    return exchanges.map((exchange) => {
      return {
        ...exchange,
        displayedContributions: this.mapToDisplayedContributions(
          exchange.flattenContributions,
          methodIndicator,
          contributionType
        ),
      };
    });
  }

  private mapToDisplayedContributions(
    contributions: Array<Contribution> | undefined,
    methodIndicator: MethodIndicator | undefined,
    contributionType: ContributionTypeName | undefined
  ): Array<Contribution> {
    if (
      contributions === undefined ||
      methodIndicator === undefined ||
      contributionType === undefined
    ) {
      return [];
    }
    const filteredContributions = this.contributionService.findContributions(
      contributions,
      methodIndicator,
      { type: contributionType }
    );
    return contributionsTreeLeaves(contributionType).map((leaf) => {
      return {
        name: leaf.name,
        displayName: leaf.displayName,
        type: leaf.type,
        indicator: leaf.indicator,
        amount: filteredContributions.find((c) => c.name === leaf.name)?.amount,
        innerContributions: [],
        innerFamilies: [],
      };
    });
  }
}
