<mat-form-field class="narrow filter">
  <mat-label>Contribution name</mat-label>
  <mat-select
    [formControl]="contributionNameFilterCtrl"
    [multiple]="false"
    [value]="value?.name"
    (selectionChange)="onValueChange()"
  >
    <mat-option *ngFor="let option of options" [value]="option.name"
      >{{ option.displayName }}
    </mat-option>
  </mat-select>
</mat-form-field>
