<tr>
  <td class="label" colspan="2">
    <app-copiable-id
      size="small"
      [copiableId]="dataset.id"
      tooltipPosition="right"
      >{{ referenceProduct.name }}
    </app-copiable-id>
  </td>
  <td>{{ referenceProduct.allocation | number: "1.1-2" }}%</td>
  <td class="amount">{{ referenceProduct.amount }}</td>
  <td>{{ referenceProduct.unit | formatUnit }}</td>
  <td
    [ngClass]="[
      'contribution',
      contribution.type | slugify,
      contribution.name | slugify,
    ]"
    [attr.colspan]="contribution.colspan"
    [attr.rowspan]="contribution.rowspan"
    *ngFor="let contribution of contributionsRootLevel"
  >
    {{ contribution.amount | displayAmount | default: "N/A" }}
    {{ methodIndicator?.unit | formatUnit }}
  </td>
  <td
    exchangeCommentToggle
    [id]="dataset.exchanges.referenceProduct.displayId"
    [comment]="dataset.exchanges.referenceProduct.comment"
    [isExpanded]="
      expandedCommentId === dataset.exchanges.referenceProduct.displayId
    "
    (expandedCommentIdChange)="onExpandedCommentIdChange($event)"
  ></td>
  <td></td>
</tr>
<ng-container
  *ngFor="let contributions of contributionsBelowRootLevel; let i = index"
>
  <tr>
    <th colspan="5"></th>
    <ng-container *ngFor="let contribution of contributions">
      <th
        *ngIf="!contribution.virtual"
        [ngClass]="[
          'top-separator',
          'contribution',
          'header',
          contribution.type | slugify,
          contribution.name | slugify,
        ]"
        [attr.colspan]="contribution.colspan"
      >
        {{ contribution.displayName }}
      </th>
    </ng-container>
    <th colspan="2"></th>
  </tr>
  <tr>
    <td colspan="5"></td>
    <ng-container *ngFor="let contribution of contributions">
      <td
        *ngIf="!contribution.virtual"
        [ngClass]="[
          'contribution',
          contribution.type | slugify,
          contribution.name | slugify,
        ]"
        [attr.colspan]="contribution.colspan"
        [attr.rowspan]="contribution.rowspan"
      >
        <app-contribution-value
          [amount]="contribution.amount"
          [referenceAmount]="totalDirectContribution"
          [showPercentages]="showPercentages"
          [showBar]="false"
        ></app-contribution-value>
      </td>
    </ng-container>
    <td colspan="2"></td>
  </tr>
</ng-container>
<tr
  *ngIf="expandedCommentId === dataset.exchanges.referenceProduct.displayId"
  exchangeComment
  [comment]="dataset.exchanges.referenceProduct.comment"
  [totalNbColumns]="nbColumns.total"
></tr>
<tr
  *ngIf="datasetWarningMessage"
  exchangeComment
  [comment]="datasetWarningMessage"
  [totalNbColumns]="nbColumns.total"
  type="warn"
></tr>
