import {MethodIndicator} from '@app/modules/reference-data/modules/method/models/method.model';

export type ContributionTypeName =
  | 'DIRECT'
  | 'OBSOLETE_FLAG'
  | 'FLAG_LUC'
  | 'SPLIT_GAS'
  | 'ACTIVITY_CATEGORY';

export function contributionTypeLabel(
  contributionType: ContributionTypeName
): string {
  switch (contributionType) {
    case 'DIRECT':
      return 'Direct';
    case 'OBSOLETE_FLAG':
      return 'FLAG split (obsolete)';
    case 'FLAG_LUC':
      return 'FLAG Land use change';
    case 'SPLIT_GAS':
      return 'Gas split';
    case 'ACTIVITY_CATEGORY':
      return 'Activity category and FLAG split';
  }
}

export type ContributionType = {
  name: ContributionTypeName;
  displayName: string;
  family: string;
  position: number;
  children: Array<ContributionType>;
}

function filterByFamily(contributionType: ContributionType, contributionFamily: string): Array<ContributionType> {
  const childFamilies = contributionType.children.map(c => filterByFamily(c, contributionFamily)).flat();
  return contributionFamily.includes(contributionType.family) ? [contributionType, ...childFamilies] : childFamilies;
}

export function findContributionTypesByFamily(contributionType: ContributionType, contributionFamily: string): Array<ContributionType> {
  return filterByFamily(contributionType, contributionFamily);
}

export const DIRECT_TYPE : ContributionType = {
  name: "DIRECT",
  displayName: "Total",
  family: "DIRECT",
  children: [],
  position: 1
};

// TODO solve mixed concerns in this model
export type Contribution = {
  name: string;
  displayName: string;
  type: ContributionTypeName;
  indicator: MethodIndicator;
  innerContributions: Array<Contribution>;
  innerFamilies: Array<ContributionFamily>;
  colspan?: number;
  rowspan?: number;
  virtual?: boolean;
  amount?: number;
};

export type ContributionFamily = {
  type: ContributionTypeName;
  innerContributions: Array<Contribution>;
};

// TODO build the tree structure below based on the backend tree structure for contributions, when available in the API
export const DIRECT: Contribution = {
  name: 'DIRECT',
  displayName: 'Total',
  type: 'DIRECT',
  indicator: undefined!, // won't be undefined when read from the backend
  innerContributions: [],
  innerFamilies: [],
};

const FLAG: Contribution = {
  name: 'FLAG',
  displayName: 'FLAG',
  type: 'OBSOLETE_FLAG',
  indicator: undefined!,
  innerContributions: [],
  innerFamilies: [],
};

const NON_FLAG: Contribution = {
  name: 'NON_FLAG',
  displayName: 'Non-FLAG',
  type: 'OBSOLETE_FLAG',
  indicator: undefined!,
  innerContributions: [],
  innerFamilies: [],
};

const FLAG_LUC: Contribution = {
  name: 'FLAG_LUC',
  displayName: 'LUC',
  type: 'OBSOLETE_FLAG',
  indicator: undefined!,
  innerContributions: [],
  innerFamilies: [],
};

const FLAG_NON_LUC: Contribution = {
  name: 'FLAG_NON_LUC',
  displayName: 'Other',
  type: 'OBSOLETE_FLAG',
  indicator: undefined!,
  innerContributions: [],
  innerFamilies: [],
};

const CO2_FOSSIL: Contribution = {
  name: 'CO2_FOSSIL',
  displayName: 'CO2, fossil',
  type: 'SPLIT_GAS',
  indicator: undefined!,
  innerContributions: [],
  innerFamilies: [],
};

const CO2_LAND_TRANSFORMATION: Contribution = {
  name: 'CO2_LAND_TRANSFORMATION',
  displayName: 'CO2, land transformation',
  type: 'SPLIT_GAS',
  indicator: undefined!,
  innerContributions: [],
  innerFamilies: [],
};

const CH4_FOSSIL: Contribution = {
  name: 'CH4_FOSSIL',
  displayName: 'CH4, fossil',
  type: 'SPLIT_GAS',
  indicator: undefined!,
  innerContributions: [],
  innerFamilies: [],
};

const CH4_BIOGENIC: Contribution = {
  name: 'CH4_BIOGENIC',
  displayName: 'CH4, biogenic',
  type: 'SPLIT_GAS',
  indicator: undefined!,
  innerContributions: [],
  innerFamilies: [],
};

const CH4_LAND_TRANSFORMATION: Contribution = {
  name: 'CH4_LAND_TRANSFORMATION',
  displayName: 'CH4, land transformation',
  type: 'SPLIT_GAS',
  indicator: undefined!,
  innerContributions: [],
  innerFamilies: [],
};

const N2O: Contribution = {
  name: 'N2O',
  displayName: 'N2O',
  type: 'SPLIT_GAS',
  indicator: undefined!,
  innerContributions: [],
  innerFamilies: [],
};

const HFC: Contribution = {
  name: 'HFC',
  displayName: 'HFCs',
  type: 'SPLIT_GAS',
  indicator: undefined!,
  innerContributions: [],
  innerFamilies: [],
};

const PFC: Contribution = {
  name: 'PFC',
  displayName: 'PFCs',
  type: 'SPLIT_GAS',
  indicator: undefined!,
  innerContributions: [],
  innerFamilies: [],
};

const SF6: Contribution = {
  name: 'SF6',
  displayName: 'SF6',
  type: 'SPLIT_GAS',
  indicator: undefined!,
  innerContributions: [],
  innerFamilies: [],
};

const NF3: Contribution = {
  name: 'NF3',
  displayName: 'NF3',
  type: 'SPLIT_GAS',
  indicator: undefined!,
  innerContributions: [],
  innerFamilies: [],
};

const OTHER_GAS: Contribution = {
  name: 'OTHER_GAS',
  displayName: 'Other gas',
  type: 'SPLIT_GAS',
  indicator: undefined!,
  innerContributions: [],
  innerFamilies: [],
};

export const ContributionsTree: Map<
  ContributionTypeName,
  Array<Array<Contribution>>
> = new Map([
  ['DIRECT', [[DIRECT]]],
  [
    'OBSOLETE_FLAG',
    [
      [{...DIRECT, colspan: 3}],
      [
        {...FLAG, colspan: 2},
        {...NON_FLAG, rowspan: 3},
      ],
      [FLAG_LUC, FLAG_NON_LUC, {...NON_FLAG, virtual: true}],
    ],
  ],
  [
    'SPLIT_GAS',
    [
      [{...DIRECT, colspan: 11}],
      [
        CO2_FOSSIL,
        CO2_LAND_TRANSFORMATION,
        CH4_FOSSIL,
        CH4_BIOGENIC,
        CH4_LAND_TRANSFORMATION,
        N2O,
        HFC,
        PFC,
        SF6,
        NF3,
        OTHER_GAS,
      ],
    ],
  ],
]);

export function contributionsTreeLeaves(
  contributionType: ContributionTypeName | undefined
): Array<Contribution> {
  if (contributionType === undefined) return [];
  const nodes = ContributionsTree.get(contributionType) ?? [];
  return nodes.length > 0 ? nodes[nodes.length - 1] : [];
}
