import { Component, Input } from '@angular/core';
import {
  ExchangeEmbeddedProduct,
  getAllExchangeEmbeddedProducts,
} from '@app/modules/dataset/modules/embedded-product/models/embedded-product.model';
import { Dataset } from '@app/modules/dataset/models/dataset.model';
import { EmbeddedProduct } from '@app/modules/dataset/models/embedded.product.model';

@Component({
  selector: 'app-embedded-product-tab',
  templateUrl: './embedded-product-tab.component.html',
  styleUrl: './embedded-product-tab.component.scss',
  styles: [],
})
export class EmbeddedProductTabComponent {
  _dataset!: Dataset;

  @Input()
  get dataset(): Dataset {
    return this._dataset;
  }

  set dataset(dataset: Dataset) {
    this._dataset = dataset;
    if (this._dataset.embeddedProducts.length >= 1) {
      this.selectEmbeddedProduct(this._dataset.embeddedProducts[0]);
    }
  }

  selectedProduct: EmbeddedProduct | undefined;
  showPercentages = false;
  exchangesContribution: ExchangeEmbeddedProduct[];

  constructor() {
    this.exchangesContribution = [];
  }

  selectEmbeddedProduct(embeddedProduct: EmbeddedProduct): void {
    this.selectedProduct = embeddedProduct;

    this.exchangesContribution = getAllExchangeEmbeddedProducts(
      this._dataset.exchanges.fromTechnosphere,
      embeddedProduct.type,
    );
  }

  onShowPercentageChange(showPercentages: boolean): void {
    this.showPercentages = showPercentages;
  }
}
