<table class="simple-table">
  <h2>Embedded Product</h2>
  <div>
    <mat-form-field>
      <mat-select
        placeholder="Embedded Product"
        [(value)]="selectedProduct"
        (selectionChange)="selectEmbeddedProduct($event.value)"
      >
        <mat-option *ngFor="let ep of dataset.embeddedProducts" [value]="ep">
          {{ ep | displayEmbeddedProductLabel }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <mat-slide-toggle
    color="primary"
    *ngIf="selectedProduct"
    [checked]="showPercentages"
    (change)="onShowPercentageChange($event.checked)"
  >
    <div>Show percentages</div>
  </mat-slide-toggle>
  <h2>Reference Product</h2>
  <tr>
    <th colspan="3"></th>
    <th>Allocation</th>
    <th>Amount</th>
    <th>Unit</th>
    <th [ngClass]="['contribution']">Total</th>
  </tr>
  <tr>
    <td class="label" colspan="3">
      <app-copiable-id
        [copiableId]="dataset.id"
        size="small"
        tooltipPosition="right"
      >
        <a
          *ngIf="true"
          routerLinkActive="active"
          [routerLink]="['/datasets', dataset.id]"
          >{{ dataset.exchanges.referenceProduct.name }}</a
        >
      </app-copiable-id>
    </td>
    <td>
      {{ dataset.exchanges.referenceProduct.allocation | number : "1.1-2" }}%
    </td>

    <td>{{ dataset.exchanges.referenceProduct.amount }}</td>
    <td>{{ dataset.exchanges.referenceProduct.unit }}</td>
    <td [ngClass]="['contribution']">
      <ng-container *ngIf="selectedProduct"
        >{{ selectedProduct.amount | displayAmount }}
        {{ selectedProduct.unit }}
      </ng-container>
    </td>
  </tr>

  <ng-container *ngIf="selectedProduct">
    <h2>
      Contribution Analysis for
      {{ selectedProduct | displayEmbeddedProductLabel }}
    </h2>
    <tr>
      <th colspan="3"></th>
      <th></th>
      <th>Amount</th>
      <th>Unit</th>
      <th [ngClass]="['contribution']">Contribution</th>
    </tr>
    <ng-container *ngFor="let displayableExchange of exchangesContribution">
      <tr
        app-contribution-analysis-row
        [exchangeContribution]="displayableExchange"
        [selectedProduct]="selectedProduct"
        [showPercentage]="showPercentages"
      ></tr>
    </ng-container>
  </ng-container>
</table>
