import { NB_COLUMNS_EXCLUDING_CONTRIBUTION } from '@app/modules/dataset/modules/exchange/components/exchange-tab/exchange-table/exchange-table.model';
import {
  contributionsTreeLeaves,
  ContributionTypeName,
} from '@app/modules/dataset/modules/contribution/model/contribution.model';

export function nbColumns(contributionType: ContributionTypeName | undefined): {
  total: number;
  contribution: number;
} {
  const nbContributionColumns =
    contributionsTreeLeaves(contributionType).length;
  const totalNbColumns =
    NB_COLUMNS_EXCLUDING_CONTRIBUTION + nbContributionColumns;
  return {
    total: totalNbColumns,
    contribution: nbContributionColumns,
  };
}
