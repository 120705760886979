import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { MethodIndicator } from '@app/modules/reference-data/modules/method/models/method.model';
import { CoProduct } from '@app/modules/dataset/models/dataset.model';
import { ContributionTypeName } from '@app/modules/dataset/modules/contribution/model/contribution.model';
import { nbColumns } from '../exchange-table-section/exchange-table.utils';

@Component({
  selector: '[exchangeCoProducts][coProducts]',
  templateUrl: './exchange-details-coproducts.component.html',
})
export class ExchangeDetailsCoproductsComponent implements OnChanges {
  @Input()
  coProducts!: Array<CoProduct>;

  @Input()
  methodIndicator: MethodIndicator | undefined;

  @Input()
  contributionType: ContributionTypeName | undefined;

  @Input()
  expandedCommentId: string | undefined;

  @Output()
  expandedCommentIdChange = new EventEmitter<string>();

  nbColumns = {
    total: 0,
    contribution: 0,
  };

  ngOnChanges(): void {
    this.nbColumns = nbColumns(this.contributionType);
  }

  onExpandedCommentIdChange(expandedId: string): void {
    this.expandedCommentIdChange.emit(expandedId);
  }
}
