<app-select-search
  size="wide"
  [multiple]="false"
  [control]="indicatorFilterCtrl"
  [list]="this.options ?? []"
  label="Select emission factor"
  displayedField="displayName"
  valueField="id"
  (valueChange)="onValueChange()"
></app-select-search>
