import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ContributionTypeName } from '@app/modules/dataset/modules/contribution/model/contribution.model';
import { MethodIndicator } from '@app/modules/reference-data/modules/method/models/method.model';
import { BioExchange } from '../../../../models/exchange.model';
import { ExchangeWithContributionMapperService } from '@app/modules/dataset/modules/exchange/mappers/exchange-with-contribution-mapper.service';
import { nbColumns } from '@app/modules/dataset/modules/exchange/components/exchange-tab/exchange-table/exchange-table-section/exchange-table.utils';

@Component({
  selector: '[exchangeBiosphere][exchanges]',
  templateUrl: './exchange-details-biosphere.component.html',
  styleUrl: './exchange-details-biosphere.component.scss',
})
export class ExchangeDetailsBiosphereComponent implements OnChanges {
  @Input()
  exchanges!: BioExchange[];

  @Input()
  totalDirectContribution: number | undefined;

  @Input()
  methodIndicator: MethodIndicator | undefined;

  @Input()
  contributionType: ContributionTypeName | undefined;

  @Input()
  showPercentages = false;

  @Input()
  expandedCommentId: string | undefined;

  @Output()
  expandedCommentIdChange = new EventEmitter<string>();

  displayableExchanges!: Array<
    BioExchange & {
      differentAsPrevious: boolean;
    }
  >;

  nbColumns = {
    total: 0,
  };

  constructor(
    private readonly exchangeWithContributionMapperService: ExchangeWithContributionMapperService<BioExchange>,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      'exchanges' in changes ||
      'methodIndicator' in changes ||
      'contributionType' in changes
    ) {
      this.displayableExchanges = this.exchangeWithContributionMapperService
        .from(this.exchanges, this.methodIndicator, this.contributionType)
        .map((exchange, i, exchanges) => {
          return {
            ...exchange,
            differentAsPrevious: this.differentAsPrevious(
              exchange,
              exchanges[i - 1],
            ),
          };
        });
      this.nbColumns = nbColumns(this.contributionType);
    }
  }

  onExpandedCommentIdChange(expandedId: string): void {
    this.expandedCommentIdChange.emit(expandedId);
  }

  private differentAsPrevious(
    exchange: BioExchange,
    previous: BioExchange | undefined,
  ): boolean {
    return previous?.type != exchange.type && previous?.type !== undefined;
  }
}
