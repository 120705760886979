import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ContributionTypeName } from '@app/modules/dataset/modules/contribution/model/contribution.model';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-contribution-type-filter[options][value]',
  templateUrl: './contribution-type-filter.component.html',
})
export class ContributionTypeFilterComponent implements OnChanges {
  @Input()
  options: Array<ContributionTypeName> | null = [];

  @Input()
  value!: ContributionTypeName | null;

  @Output()
  valueChange = new EventEmitter<ContributionTypeName | null>();

  contributionTypesFilterCtrl = new FormControl<ContributionTypeName | null>(null);

  ngOnChanges(changes: SimpleChanges): void {
    if ('value' in changes) {
      this.contributionTypesFilterCtrl.setValue(this.value, {
        emitEvent: false,
      });
    }
  }

  onValueChange(): void {
    this.valueChange.emit(this.contributionTypesFilterCtrl.value);
  }
}
