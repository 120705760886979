<h1 mat-dialog-title>Upgrade datasets in the basket</h1>
<div mat-dialog-content>
  <mat-stepper linear #stepper>
    <mat-step editable="false">
      <p>
        This procedure will upgrade every dataset currently in your basket to
        the <span class="bold italic">latest</span> available version of its
        corresponding database. If no new version is available, or the upgrade
        could not be done because of complex changes in future versions (merges,
        splits) the dataset will remain unchanged.
      </p>
      <h3>
        Your current basket will be overwritten, make sure to export it
        beforehand if necessary!
      </h3>
    </mat-step>
    <mat-step>
      <div fxFlex>
        <h3>{{ upgradedDatasets.length }} datasets after upgrade</h3>
        <div class="datasets-list">
          <ul>
            <li *ngFor="let item of upgradedDatasets">
              {{ item.exchanges.referenceProduct.name }}
              <span class="italic"
                >({{ item.description.database.name }}
                {{ item.description.database.version }})</span
              >
            </li>
          </ul>
        </div>
      </div>
    </mat-step>
  </mat-stepper>
</div>
<mat-dialog-actions align="end">
  <button mat-stroked-button [mat-dialog-close]="undefined">Cancel</button>
  <button
    [disabled]="isFetchingDatasets"
    mat-raised-button
    color="primary"
    (click)="doUpdate(stepper)"
    *ngIf="stepper.selectedIndex === 0; else step2"
  >
    Next
    <mat-icon *ngIf="isFetchingDatasets"
      ><mat-spinner diameter="20"></mat-spinner
    ></mat-icon>
  </button>
  <ng-template #step2>
    <button mat-raised-button color="primary" (click)="importAndClose()">
      Put in basket
    </button>
  </ng-template>
</mat-dialog-actions>
