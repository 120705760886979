<ng-container *ngIf="dataset">
  <app-copiable-id [copiableId]="dataset.id">
    <a [routerLink]="['.']">
      <h1>
        {{ dataset.exchanges.referenceProduct.name }}
        <app-dataset-warning
          [datasetDescription]="dataset.description"
          [displayText]="false"
        ></app-dataset-warning>
      </h1>
    </a>
  </app-copiable-id>
  <ng-container *ngIf="isInBasket$ | async; else notInBasketCase">
    <button (click)="removeFromBasket()" mat-raised-button>
      Remove from my basket
    </button>
  </ng-container>
  <ng-template #notInBasketCase>
    <button (click)="addToBasket()" color="primary" mat-raised-button>
      Add to my basket
    </button>
  </ng-template>
  <button *ngIf="isInternal$ | async" (click)="customize()" mat-raised-button>
    Customize
  </button>
</ng-container>
