import { Dataset } from '@app/modules/dataset/models/dataset.model';

export interface Classification {
  system: string;
  code: string;
  description: string;
}

export interface GroupedClassification {
  system: string;
  values: string;
}

function findClassificationOf(
  systemName: string,
  dataset: Dataset | undefined,
): Classification | undefined {
  return dataset?.description.classifications?.find(
    (c) => c.system === systemName,
  );
}

export function findCategoryOf(
  dataset: Dataset | undefined,
): Classification | undefined {
  return findClassificationOf('Activity category', dataset);
}

export function findCpcOf(
  dataset: Dataset | undefined,
): Classification | undefined {
  return findClassificationOf('CPC', dataset);
}

export function findIsicOf(
  dataset: Dataset | undefined,
): Classification | undefined {
  return findClassificationOf('ISIC', dataset);
}

export function groupClassifications(
  classifications: Classification[]
): Map<string, Classification[]> {
  const classificationMap: Map<string, Classification[]> = new Map<
    string,
    Classification[]
  >();
  for (const classification of classifications) {
    if (!classificationMap.has(classification.system)) {
      classificationMap.set(classification.system, []);
    }
    classificationMap.get(classification.system)!.push(classification);
  }
  return classificationMap;
}
