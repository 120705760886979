import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Dataset } from '@app/modules/dataset/models/dataset.model';
import { Contribution } from '@app/modules/dataset/modules/contribution/model/contribution.model';
import {
  getFilterOption,
  IndicatorGroup,
} from '@app/modules/reference-data/modules/indicator-group/models/indicator-group.model';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  map,
  Observable,
  Subject,
} from 'rxjs';
import { FormControl } from '@angular/forms';

import { IndicatorGroupService } from '@app/modules/reference-data/modules/indicator-group/services/indicator-group-service';

import { FilterOption } from '@app/modules/ui/modules/filters-group/models/filters-group.model';
import {
  extractAssessmentGroupsMatchingIndicatorGroups,
  extractIndicatorGroupsMatchingAssessmentGroups,
  isIndicatorGroupsMatchingAssessmentGroups,
} from '@app/modules/dataset/utils/dataset.utils';
import {skip, takeUntil} from 'rxjs/operators';
import {RumService} from "@app/modules/rum/rum.service";

@Component({
  selector: 'app-impact-factors-tab[dataset]',
  templateUrl: './impact-factors-tab.component.html',
  styleUrl: './impact-factors-tab.component.scss',
})
export class ImpactFactorsTabComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  dataset!: Dataset;

  groupFilterCtrl = new FormControl<string[]>([], { nonNullable: true });
  indicatorGroups: Array<IndicatorGroup> = [];
  indicatorGroupsOptions: FilterOption[] = [];

  contributions$: Observable<Contribution[]> = EMPTY;

  private onChanges$ = new BehaviorSubject<void>(undefined);
  private onDestroy$ = new Subject<void>();

  constructor(
    private readonly indicatorGroupService: IndicatorGroupService,
    private readonly rumService: RumService,
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.indicatorGroupService.getIndicatorGroups(),
      this.onChanges$,
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([indicatorGroups]) => {
        this.indicatorGroups = extractIndicatorGroupsMatchingAssessmentGroups(
          indicatorGroups,
          this.dataset.assessmentGroups,
        );
        this.indicatorGroupsOptions = this.indicatorGroups.map(
          (indicatorGroup) => getFilterOption(indicatorGroup),
        );
        if (this.isIndicatorGroupSelectionDirty()) {
          const defaultSelection = this.indicatorGroups[0]?.name;
          this.groupFilterCtrl.setValue(
            defaultSelection !== undefined ? [defaultSelection] : [],
          );
        }
      });

    const valueChangePipe = combineLatest([
      this.groupFilterCtrl.valueChanges,
      this.onChanges$,
    ]).pipe(
      map(() =>
        extractAssessmentGroupsMatchingIndicatorGroups(
          this.selectedIndicatorGroups(),
          this.dataset.assessmentGroups,
        ),
      ));
    this.contributions$ = valueChangePipe.pipe(
      map((assessmentGroups) => [
        ...new Set(assessmentGroups.flatMap((g) => g.contributions)),
      ]),
    );

    this.groupFilterCtrl.valueChanges.pipe(
      skip(1), takeUntil(this.onDestroy$)).subscribe(() =>
        this.rumService.triggerIndicatorsView(
          this.dataset,
          this.selectedIndicatorGroups().map((group) => group.name)
        )
    );

  }

  ngOnChanges(): void {
    this.onChanges$.next();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private isIndicatorGroupSelectionDirty() {
    return (
      this.selectedIndicatorGroups().length === 0 ||
      !isIndicatorGroupsMatchingAssessmentGroups(
        this.selectedIndicatorGroups(),
        this.dataset.assessmentGroups,
      )
    );
  }

  private selectedIndicatorGroups(): Array<IndicatorGroup> {
    return this.indicatorGroups.filter((indicatorGroup) =>
      this.groupFilterCtrl.value.includes(indicatorGroup.name),
    );
  }
}
