<ng-container *ngFor="let displayableExchange of displayableExchanges">
  <tr [class.compartment]="displayableExchange.differentAsPrevious">
    <td class="label">
      <app-copiable-id
        size="small"
        [copiableId]="displayableExchange.id"
        tooltipPosition="right"
      >
        {{ displayableExchange.name }}
      </app-copiable-id>
    </td>
    <td *ngIf="displayableExchange.compartment !== undefined">
      {{ displayableExchange.compartment | compartmentLabel }}
    </td>
    <td *ngIf="displayableExchange.subCompartment !== undefined">
      {{ displayableExchange.subCompartment | subCompartmentLabel }}
    </td>
    <td class="amount">{{ displayableExchange.amount | displayAmount }}</td>
    <td>{{ displayableExchange.unit | formatUnit }}</td>
    <ng-container *ngIf="displayableExchange.displayedContributions.length > 0">
      <td
        [ngClass]="[
          'contribution',
          contribution.type | slugify,
          contribution.name | slugify
        ]"
        *ngFor="let contribution of displayableExchange.displayedContributions"
      >
        <app-contribution-value
          [amount]="contribution.amount"
          [referenceAmount]="totalDirectContribution"
          [showPercentages]="showPercentages"
        ></app-contribution-value>
      </td>
    </ng-container>
    <td
      exchangeCommentToggle
      [id]="displayableExchange.displayId"
      [comment]="displayableExchange.comment"
      [isExpanded]="expandedCommentId === displayableExchange.displayId"
      (expandedCommentIdChange)="onExpandedCommentIdChange($event)"
    ></td>
    <td></td>
  </tr>
  <tr
    *ngIf="expandedCommentId === displayableExchange.displayId"
    exchangeComment
    [comment]="displayableExchange.comment"
    [totalNbColumns]="nbColumns.total"
  ></tr>
</ng-container>
