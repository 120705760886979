<table>
  <tbody>
    <tr>
      <td colspan="2">
        <h2>Activity</h2>
      </td>
    </tr>
    <tr>
      <td class="label">Name</td>
      <td class="value">{{ dataset.description.activityName }}</td>
    </tr>
    <tr>
      <td class="label">Geography</td>
      <td class="value">
        {{ dataset.description.geography! | displayGeography }}
      </td>
    </tr>
    <tr>
      <td class="label">Type</td>
      <td class="value">
        {{ dataset.description.activityType | activityTypeEnumToName }}
      </td>
    </tr>
    <tr>
      <td class="label">Unit</td>
      <td class="value">
        {{ dataset.exchanges.referenceProduct.unit }}
      </td>
    </tr>
    <tr>
      <td class="label">Database</td>
      <td class="value">
        {{ dataset.description.database.displayName }}
      </td>
    </tr>
    <ng-container
      *ngIf="dataset.description.technologyLevel as technologyLevel"
    >
      <tr>
        <td class="label">Technology level</td>
        <td class="value">
          {{ technologyLevel | labelTechnologyLevel }}
        </td>
      </tr>
    </ng-container>
    <ng-container
      *ngIf="dataset.description.productionVolume as productionVolume"
    >
      <tr>
        <td class="label">Production volume</td>
        <td class="value">
          {{ productionVolume }}
        </td>
      </tr>
    </ng-container>
    <ng-container *ngIf="dataset.description.casNumber as casNumber">
      <tr>
        <td class="label">Cas number</td>
        <td class="value">
          {{ casNumber }}
        </td>
      </tr>
    </ng-container>
    <ng-container
      *ngIf="
        dataset.description.synonyms && dataset.description.synonyms.length > 0
      "
    >
      <tr>
        <td class="label">Synonyms</td>
        <td class="value">
          <ng-container *ngFor="let synonym of dataset.description.synonyms">
            {{ synonym }}<br />
          </ng-container>
        </td>
      </tr>
    </ng-container>
    <tr>
      <td class="label">General comment</td>
      <td class="value" [innerHTML]="dataset.description.generalComment"></td>
    </tr>
    <ng-container
      *ngIf="
        dataset.description.includedActivitiesStart ||
        dataset.description.includedActivitiesEnd
      "
    >
      <tr>
        <td colspan="2">
          <h2>Included activities</h2>
        </td>
      </tr>
      <tr *ngIf="dataset.description.includedActivitiesStart">
        <td class="label">Start</td>
        <td
          class="value"
          [innerHTML]="dataset.description.includedActivitiesStart"
        ></td>
      </tr>
      <tr *ngIf="dataset.description.includedActivitiesEnd">
        <td class="label">End</td>
        <td
          class="value"
          [innerHTML]="dataset.description.includedActivitiesEnd"
        ></td>
      </tr>
    </ng-container>
    <ng-container *ngIf="dataset.description.timePeriod as tp">
      <tr>
        <td colspan="2">
          <h2>Time period</h2>
        </td>
      </tr>
      <tr>
        <td class="label">Start</td>
        <td class="value">
          {{ tp.startDate | date : "yyyy-MM-dd" }}
        </td>
      </tr>
      <tr>
        <td class="label">End</td>
        <td class="value">
          {{ tp.endDate | date : "yyyy-MM-dd" }}
        </td>
      </tr>
    </ng-container>
    <ng-container *ngIf="dataset.description.modelling as mod">
      <tr>
        <td colspan="2">
          <h2>Modelling</h2>
        </td>
      </tr>
      <tr>
        <td class="label">System model</td>
        <td class="value">
          {{ mod.systemModel }}
        </td>
      </tr>
      <tr>
        <td class="label">Sampling procedure</td>
        <td class="value">
          {{ mod.samplingProcedure }}
        </td>
      </tr>
      <tr>
        <td class="label">Extrapolation</td>
        <td class="value">
          {{ mod.extrapolation }}
        </td>
      </tr>
      <tr>
        <td class="label">Percent</td>
        <td class="value">
          {{ mod.percent }}
        </td>
      </tr>
    </ng-container>
    <ng-container *ngIf="dataset.description.classifications">
      <tr>
        <td colspan="2">
          <h2>Classifications</h2>
        </td>
      </tr>
      <tr *ngFor="let classification of this.groupedClassifications">
        <td class="label">{{ classification.system }}</td>
        <td class="value" [innerHTML]="classification.values"></td>
      </tr>
    </ng-container>
    <ng-container *ngIf="dataset.description.author as author">
      <tr>
        <td colspan="2">
          <h2>Author</h2>
        </td>
      </tr>
      <tr>
        <td class="label">Name</td>
        <td class="value">
          {{ author.name }}
        </td>
      </tr>
      <tr>
        <td class="label">Email</td>
        <td class="value">
          {{ author.email }}
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
