import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MethodIndicator } from '@app/modules/reference-data/modules/method/models/method.model';

@Component({
  selector: 'app-indicator-filter[options][value]',
  templateUrl: './indicator-filter.component.html',
})
export class IndicatorFilterComponent implements OnChanges {
  @Input()
  options: Array<MethodIndicator> | null = [];

  @Input()
  value!: MethodIndicator | null;

  @Output()
  valueChange = new EventEmitter<MethodIndicator | null>();

  indicatorFilterCtrl = new FormControl<string | null>(null);

  ngOnChanges(changes: SimpleChanges): void {
    if ('value' in changes) {
      this.indicatorFilterCtrl.setValue(this.value?.id ?? null, {
        emitEvent: false,
      });
    }
  }

  onValueChange(): void {
    const indicator = this.options?.find(
      (option) => option.id === this.indicatorFilterCtrl.value
    );
    this.valueChange.emit(indicator);
  }
}
