import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DatasetWarningModule } from '@app/modules/dataset/modules/dataset-warning/dataset-warning.module';
import { ExchangeTabComponent } from '@app/modules/dataset/modules/exchange/components/exchange-tab/exchange-tab.component';
import { ExchangeDetailsRefProductComponent } from '@app/modules/dataset/modules/exchange/components/exchange-tab/exchange-table/exchange-details-refproduct/exchange-details-ref-product.component';
import { ExchangeMapperService } from '@app/modules/dataset/modules/exchange/mappers/exchange-mapper.service';
import { SankeyModule } from '@app/modules/dataset/modules/exchange/modules/sankey/sankey.module';
import { MaterialModule } from '@app/modules/material/material.module';
import { ReferenceDataModule } from '@app/modules/reference-data/reference-data.module';
import { UiModule } from '@app/modules/ui/ui.module';
import { ContributionValueComponent } from './components/exchange-tab/exchange-table/contribution-value/contribution-value.component';
import { ExchangeDetailsBiosphereComponent } from './components/exchange-tab/exchange-table/exchange-details-biosphere/exchange-details-biosphere.component';
import { ExchangeDetailsTechnosphereComponent } from './components/exchange-tab/exchange-table/exchange-details-technosphere/exchange-details-technosphere.component';
import { ExchangeTableSectionComponent } from './components/exchange-tab/exchange-table/exchange-table-section/exchange-table-section.component';
import { ExchangeTableComponent } from './components/exchange-tab/exchange-table/exchange-table.component';
import { ExchangeDetailsSubtotalComponent } from './components/exchange-tab/exchange-table/exchange-details-subtotal/exchange-details-subtotal.component';
import { ExchangeCommentComponent } from './components/exchange-tab/exchange-table/exchange-comment/exchange-comment.component';
import { ExchangeCommentToggleComponent } from './components/exchange-tab/exchange-table/exchange-comment-toggle/exchange-comment-toggle.component';
import { ExchangeLabelWarningComponent } from './components/exchange-tab/exchange-table/exchange-label-warning/exchange-label-warning.component';
import { ExchangeWarningComponent } from './components/exchange-tab/exchange-table/exchange-warning/exchange-warning.component';
import { ExchangeDetailsCoproductsComponent } from './components/exchange-tab/exchange-table/exchange-details-coproducts/exchange-details-coproducts.component';
import { ContributionNameFilterComponent } from '@app/modules/dataset/modules/exchange/components/exchange-tab/contribution-name-filter/contribution-name-filter.component';
import { ContributionModule } from '@app/modules/dataset/modules/contribution/contribution.module';
import { GroupFilterComponent } from '@app/modules/dataset/modules/exchange/components/exchange-tab/group-filter/group-filter.component';
import { IndicatorFilterComponent } from '@app/modules/dataset/modules/exchange/components/exchange-tab/indicator-filter/indicator-filter.component';
import { ContributionTypeFilterComponent } from '@app/modules/dataset/modules/exchange/components/exchange-tab/contribution-type-filter/contribution-type-filter.component';
import { ContributionSelectionModule } from '@app/modules/dataset/modules/contribution-selection/contribution-selection.module';
import { NgStringPipesModule } from 'ngx-pipes';
import {
  ContributionFiltersComponent
} from "@app/modules/dataset/modules/exchange/components/exchange-tab/contribution-filters/contribution-filters.component";


@NgModule({
  declarations: [
    ExchangeTabComponent,
    ExchangeDetailsTechnosphereComponent,
    ExchangeDetailsBiosphereComponent,
    ExchangeDetailsRefProductComponent,
    ExchangeTableComponent,
    ExchangeTableSectionComponent,
    ContributionValueComponent,
    ExchangeDetailsSubtotalComponent,
    ExchangeCommentComponent,
    ExchangeCommentToggleComponent,
    ExchangeLabelWarningComponent,
    ExchangeWarningComponent,
    ExchangeDetailsCoproductsComponent,
    ContributionNameFilterComponent,
    GroupFilterComponent,
    IndicatorFilterComponent,
    ContributionTypeFilterComponent,
    ContributionFiltersComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    ReferenceDataModule,
    SankeyModule,
    UiModule,
    NgStringPipesModule,
    DatasetWarningModule,
    ReactiveFormsModule,
    ContributionModule,
    ContributionSelectionModule,
  ],
  providers: [ExchangeMapperService],
  exports: [ExchangeTabComponent, ContributionValueComponent],
})
export class ExchangeModule {}
