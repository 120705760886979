<td class="label" colspan="3">
  <app-copiable-id
    [copiableId]="exchangeContribution.exchangeId"
    size="small"
    tooltipPosition="right"
  >
    <a
      *ngIf="true"
      routerLinkActive="active"
      [routerLink]="['/datasets', exchangeContribution.exchangeId]"
      >{{ exchangeContribution.exchangeName }}</a
    >
  </app-copiable-id>
</td>
<td></td>
<td class="amount">
  {{ exchangeContribution.exchangeAmount | number : "1.1-2" }}
</td>
<td>{{ exchangeContribution.exchangeUnit }}</td>
<td [ngClass]="['contribution']" *ngIf="exchangeContribution.embeddedProduct">
  <app-contribution-value
    [amount]="exchangeContribution.embeddedProduct.amount"
    [referenceAmount]="selectedProduct.amount"
    [showPercentages]="showPercentage"
  ></app-contribution-value>
</td>
