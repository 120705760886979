import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MethodIndicator } from '@app/modules/reference-data/modules/method/models/method.model';
import {
  Contribution,
  ContributionTypeName,
} from '@app/modules/dataset/modules/contribution/model/contribution.model';
import {ContributionService} from "@app/modules/dataset/modules/contribution/services/contribution.service";

@Component({
  selector:
    '[exchangeSubtotal][title][contributions][methodIndicator][contributionType]',
  templateUrl: './exchange-details-subtotal.component.html',
  styleUrl: './exchange-details-subtotal.component.scss',
})
export class ExchangeDetailsSubtotalComponent implements OnChanges {
  constructor(private readonly contributionService: ContributionService) {}

  @Input()
  title!: string;

  @Input()
  contributions!: Array<Contribution>;

  @Input()
  totalDirectContribution: number | undefined;

  @Input()
  methodIndicator!: MethodIndicator;

  @Input()
  contributionType!: ContributionTypeName;

  @Input()
  showPercentages = false;

  totalContributions: Array<Contribution> = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (
      'contributions' in changes ||
      'methodIndicator' in changes ||
      'contributionType' in changes
    ) {
      const contributions = this.contributions.filter(
        (c) => c.indicator.id === this.methodIndicator?.id,
      );

      this.totalContributions = this.contributionService.totalContributions(
        contributions,
        this.methodIndicator,
        this.contributionType,
      );
    }
  }
}
