import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DatasetDetailComponent } from '@app/modules/dataset/modules/dataset-detail/pages/dataset-detail/dataset-detail.component';
import { DatasetsSearchComponent } from '@app/modules/dataset/pages/datasets-search/datasets-search.component';
import { AuthGuard } from '@auth0/auth0-angular';

const routes: Routes = [
  {
    path: 'datasets',
    component: DatasetsSearchComponent,
    data: {
      showBasket: true,
      menuButtons: [
        { linkTo: '/datasets', label: 'Search LCIA datasets' },
        {
          linkTo: '/characterization-factors',
          label: 'Characterization factors',
        },
      ],
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'datasets/:datasetId',
    component: DatasetDetailComponent,
    data: {
      showBasket: true,
      menuButtons: [
        { linkTo: '/datasets', label: 'Search LCIA datasets' },
        {
          linkTo: '/characterization-factors',
          label: 'Characterization factors',
        },
      ],
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'dataset/:datasetId',
    redirectTo: 'datasets/:datasetId',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class DatasetRoutingModule {}
