import { Component, Input, OnChanges } from '@angular/core';

import { Exchange } from '@app/modules/dataset/modules/exchange/models/exchange.model';
import { SectionType } from './section-type.model';
import { MethodIndicator } from '@app/modules/reference-data/modules/method/models/method.model';
import {
  Contribution,
  contributionsTreeLeaves,
  ContributionTypeName
} from "@app/modules/dataset/modules/contribution/model/contribution.model";

@Component({
  selector: '[exchangeTableSection][title][type][exchanges]',
  templateUrl: './exchange-table-section.component.html',
})
export class ExchangeTableSectionComponent implements OnChanges {
  readonly SPLIT_GAS: ContributionTypeName = 'SPLIT_GAS';

  @Input()
  title!: string;

  @Input()
  type!: SectionType;

  @Input()
  exchanges!: Array<Exchange>;

  @Input()
  contributionType: ContributionTypeName | undefined;

  @Input()
  methodIndicator: MethodIndicator | undefined;

  SectionType = SectionType;

  contributionColumns: Array<Contribution> = [];

  ngOnChanges(): void {
    this.contributionColumns = contributionsTreeLeaves(this.contributionType);
  }
}
