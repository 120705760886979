import { TechnoExchange } from '@app/modules/dataset/modules/exchange/models/exchange.model';
import {
  EmbeddedProduct,
  EmbeddedProductType,
  getEmbeddedProduct,
} from '@app/modules/dataset/models/embedded.product.model';

export interface ExchangeEmbeddedProduct {
  exchangeId: string | undefined;
  exchangeName: string;
  exchangeAmount: number;
  exchangeUnit: string;
  embeddedProduct: EmbeddedProduct;
}

export function getExchangeEmbeddedProduct(
  exchange: TechnoExchange,
  type: EmbeddedProductType
): ExchangeEmbeddedProduct {
  const embeddedProduct: EmbeddedProduct = getEmbeddedProduct(exchange, type);
  return {
    exchangeId: exchange.id,
    exchangeName: exchange.name,
    exchangeAmount: exchange.amount,
    exchangeUnit: exchange.unit,
    embeddedProduct: embeddedProduct,
  };
}

export function getAllExchangeEmbeddedProducts(
  exchanges: TechnoExchange[],
  type: EmbeddedProductType
): ExchangeEmbeddedProduct[] {
  return exchanges.map((exchange) =>
    getExchangeEmbeddedProduct(exchange, type)
  );
}
