import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ContributionType,
  DIRECT,
} from '@app/modules/dataset/modules/contribution/model/contribution.model';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-contribution-name-filter[options][value]',
  templateUrl: './contribution-name-filter.component.html',
})
export class ContributionNameFilterComponent implements OnChanges {
  @Input()
  options: Array<ContributionType> | null = [];

  @Input()
  value!: ContributionType | null;

  @Output()
  valueChange = new EventEmitter<ContributionType | null>();

  contributionNameFilterCtrl = new FormControl<string | null>(DIRECT.name);

  ngOnChanges(changes: SimpleChanges): void {
    if ('value' in changes) {
      this.contributionNameFilterCtrl.setValue(this.value?.name ?? null, {
        emitEvent: false,
      });
    }
  }

  onValueChange(): void {
    this.valueChange.emit(this.options?.find(opt => opt.name === this.contributionNameFilterCtrl.value));
  }
}
