import { ActivityTypeDto } from '@app/api/__generated__/model/activityTypeDto';
import { Exchanges } from '@app/modules/dataset/modules/exchange/models/exchange.model';
import { Parent } from '@app/modules/dataset/modules/parent/models/parent.model';
import { Database } from '@app/modules/reference-data/modules/database/models/database.model';
import { Geography } from '@app/modules/reference-data/modules/geography/models/geography.model';
import { Classification } from '../../reference-data/modules/classification/models/classification.model';
import { History } from '../modules/history/models/history.models';
import { AssessmentGroup } from './assessment.model';
import { EmbeddedProduct } from '@app/modules/dataset/models/embedded.product.model';
import {Contribution} from "@app/modules/dataset/modules/contribution/model/contribution.model";

export interface Dataset {
  id: string;
  description: DatasetDescription;
  impactIndicatorSamples: Array<ImpactAssessmentSample>;
  assessmentGroups: Array<AssessmentGroup>;
  exchanges: Exchanges;
  coProducts: Array<CoProduct>;
  parents: Array<Parent>;
  contributions: Contribution[];
  flattenContributions: Contribution[]; // temporally flatten contributions before refactoring exchanges tab
  embeddedProducts: EmbeddedProduct[];
  history: History;
  isFlag: boolean;
  isLuc: boolean;
}

export interface DatasetDescription {
  activityName: string;
  activityType: ActivityTypeDto;
  includedActivitiesStart?: string;
  includedActivitiesEnd?: string;
  synonyms?: Array<string>;
  technologyLevel?: TechnologyLevel;
  productionVolume?: number;
  casNumber?: string;
  modelling?: Modelling;
  author?: Author;
  database: Database;
  generalComment?: string;
  geography: Geography;
  timePeriod?: TimePeriod;
  classifications: Array<Classification> | undefined;
  isic: string | undefined;
}

export interface Author {
  name: string;
  email: string;
}

export interface Modelling {
  systemModel?: string;
  samplingProcedure?: string;
  extrapolation?: string;
  percent?: number;
}

export enum TechnologyLevel {
  UNDEFINED = 'UNDEFINED',
  NEW = 'NEW',
  MODERN = 'MODERN',
  CURRENT = 'CURRENT',
  OLD = 'OLD',
  OUTDATED = 'OUTDATED',
}

export interface CoProduct {
  id: string;
  name: string;
  amount?: number;
  unit: string;
  allocation?: number;
  comment?: string;
}

export interface ImpactAssessmentSample {
  methodName: string;
  indicator: ImpactIndicator;
}

export interface ImpactIndicator {
  id: string;
  categoryName: string;
  unitName: string;
  amount: number;
  groups: Array<string>;
}

export interface TimePeriod {
  startDate: string;
  endDate: string;
}
export function getGroupNames(dataset: Dataset): string[] {
  const groupNames: string[] = dataset.assessmentGroups.map((g) => g.name);
  return [...new Set(groupNames)];
}
