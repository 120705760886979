import { TechnoExchange } from '@app/modules/dataset/modules/exchange/models/exchange.model';

export type EmbeddedProductType = 'ELECTRICITY' | 'SOY' | 'PALM';
export interface EmbeddedProduct {
  type: EmbeddedProductType;
  amount: number;
  unit: string;
}

export function getEmbeddedProduct(
  exchange: TechnoExchange,
  type: EmbeddedProductType
): EmbeddedProduct {
  const embeddedProduct = exchange.embeddedProducts.find(
    (ep) => ep.type === type
  );
  if (embeddedProduct) {
    return embeddedProduct;
  } else {
    throw new Error(
      `No embedded product of type ${type} for the exchange ${exchange.name}`
    );
  }
}
