import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  Contribution,
  ContributionTypeName,
} from '@app/modules/dataset/modules/contribution/model/contribution.model';
import { Dataset } from '@app/modules/dataset/models/dataset.model';
import { MethodIndicator } from '@app/modules/reference-data/modules/method/models/method.model';
import { RefProductExchange } from '@app/modules/dataset/modules/exchange/models/exchange.model';
import { RefProductContributionsMapperService } from '@app/modules/dataset/modules/exchange/mappers/ref-product-contributions-mapper.service';
import { nbColumns } from '@app/modules/dataset/modules/exchange/components/exchange-tab/exchange-table/exchange-table-section/exchange-table.utils';

@Component({
  selector: '[exchangeRefProduct][dataset]',
  templateUrl: './exchange-details-ref-product.component.html',
})
export class ExchangeDetailsRefProductComponent implements OnChanges {
  @Input()
  dataset!: Dataset;

  @Input()
  methodIndicator: MethodIndicator | undefined;

  @Input()
  contributionType: ContributionTypeName | undefined;

  @Input()
  totalDirectContribution: number | undefined;

  @Input()
  showPercentages = false;

  @Input()
  expandedCommentId: string | undefined;

  @Output()
  expandedCommentIdChange = new EventEmitter<string>();

  get referenceProduct(): RefProductExchange {
    return this.dataset.exchanges.referenceProduct;
  }

  contributionsRootLevel: Array<Contribution> = [];
  contributionsBelowRootLevel: Array<Array<Contribution>> = [];

  nbColumns = {
    total: 0,
  };

  constructor(
    private readonly refProductContributionsMapperService: RefProductContributionsMapperService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      'dataset' in changes ||
      'methodIndicator' in changes ||
      'contributionType' in changes
    ) {
      const contributionsLevels =
        this.refProductContributionsMapperService.from(
          this.dataset.exchanges.referenceProduct,
          this.methodIndicator,
          this.contributionType
        );
      this.contributionsRootLevel = contributionsLevels[0] ?? [];
      this.contributionsBelowRootLevel = contributionsLevels.slice(1);
      this.nbColumns = nbColumns(this.contributionType);
    }
  }

  onExpandedCommentIdChange(expandedId: string): void {
    this.expandedCommentIdChange.emit(expandedId);
  }

  get datasetWarningMessage(): string | undefined {
    if (this.methodIndicator === undefined) {
      return undefined;
    }
    if (this.dataset.isFlag && this.contributionType === 'OBSOLETE_FLAG') {
      return 'This dataset represents agricultural, forestry or land activities. All its emissions are considered FLAG';
    }
    if (this.dataset.isFlag && this.contributionType === 'OBSOLETE_FLAG') {
      if (this.dataset.isLuc) {
        return (
          'This dataset represents agricultural, forestry or land LUC activities. All its emissions are' +
          ' considered FLAG land use change'
        );
      }
      return 'This dataset represents agricultural, forestry or land activities. All its emissions are considered FLAG';
    }
    return '';
  }
}
