import { Component, Input } from '@angular/core';
import { ExchangeEmbeddedProduct } from '@app/modules/dataset/modules/embedded-product/models/embedded-product.model';
import { EmbeddedProduct } from '@app/modules/dataset/models/embedded.product.model';

@Component({
  selector: '[app-contribution-analysis-row]',
  templateUrl: './contribution-analysis-row.component.html',
  styleUrl: './contribution-analysis-row.scss',
})
export class ContributionAnalysisRowComponent {
  @Input()
  exchangeContribution!: ExchangeEmbeddedProduct;
  @Input()
  selectedProduct!: EmbeddedProduct;
  @Input()
  showPercentage!: boolean;
}
