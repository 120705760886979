import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';

import { Dataset } from '@app/modules/dataset/models/dataset.model';
import { MethodIndicator } from '@app/modules/reference-data/modules/method/models/method.model';
import { SectionType } from './exchange-table-section/section-type.model';
import {Contribution, ContributionTypeName} from "@app/modules/dataset/modules/contribution/model/contribution.model";
import {ContributionService} from "@app/modules/dataset/modules/contribution/services/contribution.service";

@Component({
  selector: 'app-exchange-table',
  templateUrl: './exchange-table.component.html',
  styleUrl: './exchange-table.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ExchangeTableComponent implements OnChanges {
  readonly SPLIT_GAS: ContributionTypeName = 'SPLIT_GAS';

  @Input()
  dataset!: Dataset;

  @Input()
  methodIndicator: MethodIndicator | undefined;

  @Input()
  contributionType: ContributionTypeName | undefined;

  @Input()
  showPercentages = false;

  expandedCommentId: string | undefined;

  get hasCoProducts(): boolean {
    return this.dataset.coProducts.length > 0;
  }

  get hasFromTechnosphere(): boolean {
    return this.dataset.exchanges.fromTechnosphere.length > 0;
  }

  get hasToTechnosphere(): boolean {
    return this.dataset.exchanges.toTechnosphere.length > 0;
  }

  get hasFromBiosphere(): boolean {
    return this.dataset.exchanges.fromBiosphere.length > 0;
  }

  get hasToBiosphere(): boolean {
    return this.dataset.exchanges.toBiosphere.length > 0;
  }

  get showContribution(): boolean {
    return (
      this.methodIndicator !== undefined && this.contributionType !== undefined
    );
  }

  get showSplitGasContribution(): boolean {
    return this.contributionType === 'SPLIT_GAS';
  }

  SectionType = SectionType;

  totalDirectContribution: number | undefined;
  fromTechnosphereContributions: Array<Contribution> = [];
  fromBiosphereContributions: Array<Contribution> = [];
  toBiosphereContributions: Array<Contribution> = [];

  constructor(private contributionService: ContributionService) {}

  ngOnChanges(): void {
    if (this.showContribution) {
      this.totalDirectContribution = this.contributionService.findContribution(
        this.dataset.exchanges.referenceProduct.flattenContributions,
        this.methodIndicator!,
        'DIRECT',
        'DIRECT',
      )?.amount;

      this.fromTechnosphereContributions =
        this.dataset.exchanges.fromTechnosphere.flatMap(
          (e) => e.flattenContributions,
        );

      this.fromBiosphereContributions =
        this.dataset.exchanges.fromBiosphere.flatMap(
          (e) => e.flattenContributions,
        );

      this.toBiosphereContributions =
        this.showSplitGasContribution && this.hasToBiosphere
          ? this.totalSplitGasContributionToBiosphere()
          : this.dataset.exchanges.toBiosphere.flatMap(
              (e) => e.flattenContributions,
            );
    }
  }

  onExpandedCommentIdChange(expandedCommentId: string): void {
    this.expandedCommentId = expandedCommentId;
  }

  private totalSplitGasContributionToBiosphere() {
    return this.contributionService.totalSplitGasContributionToBiosphere(
      this.dataset.exchanges.referenceProduct.flattenContributions,
      this.fromTechnosphereContributions,
      this.methodIndicator!,
    );
  }
}
