<ng-container *ngFor="let displayableExchange of displayableExchanges">
  <tr>
    <td class="label" colspan="3">
      <app-copiable-id
        size="small"
        [copiableId]="displayableExchange.id"
        tooltipPosition="right"
      >
        <a
          *ngIf="
            displayableExchange.id && displayableExchange.navigable;
            else onlyLabel
          "
          routerLinkActive="active"
          [routerLink]="['/datasets', displayableExchange.id]"
          >{{ displayableExchange.name }}</a
        >
      </app-copiable-id>
      <ng-template #onlyLabel
        >{{ displayableExchange.name }} (no background data available)
      </ng-template>
      <app-exchange-label-warning
        [warnings]="displayableExchange.warnings"
      ></app-exchange-label-warning>
    </td>
    <td class="amount">{{ displayableExchange.amount | displayAmount }}</td>
    <td>{{ displayableExchange.unit | formatUnit }}</td>
    <ng-container *ngIf="displayableExchange.displayedContributions.length > 0">
      <td
        *ngFor="let contribution of displayableExchange.displayedContributions"
        [ngClass]="[
          'contribution',
          contribution.type | slugify,
          contribution.name | slugify
        ]"
      >
        <app-contribution-value
          [amount]="contribution.amount"
          [referenceAmount]="totalDirectContribution"
          [showPercentages]="showPercentages"
        ></app-contribution-value>
      </td>
    </ng-container>
    <td
      exchangeCommentToggle
      [id]="displayableExchange.displayId"
      [comment]="displayableExchange.comment"
      [isExpanded]="expandedCommentId === displayableExchange.displayId"
      (expandedCommentIdChange)="onExpandedCommentIdChange($event)"
    ></td>
    <td class="warn">
      <app-exchange-warning
        [warnings]="displayableExchange.warnings"
      ></app-exchange-warning>
    </td>
  </tr>
  <tr
    *ngIf="expandedCommentId === displayableExchange.displayId"
    exchangeComment
    [comment]="displayableExchange.comment"
    [totalNbColumns]="nbColumns.total"
  ></tr>
</ng-container>
