import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CharacterizationFactorsSearchComponent } from '@app/modules/characterization-factor/pages/characterization-factors-search/characterization-factors-search.component';
import { AuthGuard } from '@auth0/auth0-angular';

const routes: Routes = [
  {
    path: 'characterization-factors',
    component: CharacterizationFactorsSearchComponent,
    data: {
      showBasket: false,
      menuButtons: [
        { linkTo: '/datasets', label: 'Search LCIA datasets' },
        {
          linkTo: '/characterization-factors',
          label: 'Characterization factors',
        },
      ],
    },
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class CharacterizationFactorRoutingModule {}
