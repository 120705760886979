import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayAmount',
})
export class AmountPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) public locale: string) {}

  transform(amount: number | undefined): string | undefined {
    if (amount == undefined) {
      return undefined;
    }
    if (amount == 0) {
      return amount.toString();
    }
    if (Math.abs(amount) >= 0.01 && Math.abs(amount) < 1000) {
      return amount.toPrecision(4);
    }
    return this.writeAsPowerOfThousands(amount);
  }

  // We want to display 1e5 as 100e3 (in thousands, millions, billions...)
  writeAsPowerOfThousands(amount: number): string {
    const scientific = amount.toExponential(3);
    const [coefficient, exponent] = scientific
      .split(/[eE]/)
      .map((item) => Number(item));
    // For 1e5 extract 2
    let rest = exponent % 3;
    // For 1e-7 extract 2 ( = -1 + 3)
    if (rest < 0) {
      rest = rest + 3;
    }
    // new_exponent is now a multiple of 3 (divide by 10^rest)
    const new_exponent = exponent - rest;
    // multiply coefficient according to new_exponent  (multiply by 10^rest)
    const new_coefficient =
      Math.round(coefficient * Math.pow(10, rest) * 100_000) / 100_000;
    let exponent_string = 'e+';
    if (new_exponent < 0) {
      exponent_string = 'e';
    }
    return (
      new_coefficient.toString() + exponent_string + new_exponent.toString()
    );
  }
}
