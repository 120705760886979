import { Injectable } from '@angular/core';
import { BiosphereExchangeDto } from '@app/api/__generated__/model/biosphereExchangeDto';
import { CompartmentDto } from '@app/api/__generated__/model/compartmentDto';
import { DatasetDto } from '@app/api/__generated__/model/datasetDto';
import { ReferenceProductDto } from '@app/api/__generated__/model/referenceProductDto';
import { SubCompartmentDto } from '@app/api/__generated__/model/subCompartmentDto';
import { TechnosphereExchangeDto } from '@app/api/__generated__/model/technosphereExchangeDto';
import {
  BioExchange,
  Exchange,
  Exchanges,
  RefProductExchange,
  TechnoExchange,
} from '@app/modules/dataset/modules/exchange/models/exchange.model';
import { EmbeddedProductMapperService } from '@app/modules/dataset/mappers/embedded-product/embedded-product-mapper.service';
import * as uuid from 'uuid';
import {ContributionMapperService} from "@app/modules/dataset/modules/contribution/mappers/contribution-mapper.service";

@Injectable({
  providedIn: 'root',
})
export class ExchangeMapperService {
  constructor(
    private readonly contributionMapper: ContributionMapperService,
    private readonly embeddedProductMapper: EmbeddedProductMapperService
  ) {}

  from(datasetDto: DatasetDto): Exchanges {
    const biosphereInputs: BioExchange[] = [];
    const biosphereOutputs: BioExchange[] = [];

    const sortBioExchange = (
      a: BiosphereExchangeDto,
      b: BiosphereExchangeDto
    ) => {
      const comparedValue = a.type.localeCompare(b.type);
      return comparedValue !== 0 ? comparedValue : a.name.localeCompare(b.name);
    };

    datasetDto.biosphereExchanges
      .sort(sortBioExchange)
      .forEach((dto: BiosphereExchangeDto) => {
        if (dto.type === 'RESOURCES') {
          biosphereInputs.push(this.mapBiosphereExchange(dto));
        } else {
          biosphereOutputs.push(this.mapBiosphereExchange(dto));
        }
      });

    const technosphereInputs: TechnoExchange[] = [];
    const technosphereOutputs: TechnoExchange[] = [];

    datasetDto.technosphereExchanges
      .sort((a, b) => a.name.localeCompare(b.name))
      .forEach((dto: TechnosphereExchangeDto) => {
        if (dto.type === 'AVOIDED_PRODUCTS') {
          technosphereOutputs.push(this.mapTechnosphereExchange(dto));
        } else {
          technosphereInputs.push(this.mapTechnosphereExchange(dto));
        }
      });

    return {
      referenceProduct: this.mapReferenceProduct(datasetDto.referenceProduct),
      fromBiosphere: biosphereInputs,
      toBiosphere: biosphereOutputs,
      fromTechnosphere: technosphereInputs,
      toTechnosphere: technosphereOutputs,
    };
  }

  private mapExchange(
    dto: ReferenceProductDto | BiosphereExchangeDto | TechnosphereExchangeDto
  ): Exchange {
    return {
      displayId: uuid.v4(),
      name: dto.name,
      amount:
        typeof dto.amount === 'string'
          ? Number.parseFloat(dto.amount)
          : dto.amount,
      unit: dto.unit,
      comment: dto.comment,
      contributions: this.contributionMapper.fromList(dto.contributions),
      flattenContributions: this.contributionMapper.flattenFromList(
        dto.contributions
      ),
    };
  }

  private mapReferenceProduct(dto: ReferenceProductDto): RefProductExchange {
    return {
      ...this.mapExchange(dto),
      allocation: dto.allocation,
    };
  }

  private mapBiosphereExchange(dto: BiosphereExchangeDto): BioExchange {
    return {
      ...this.mapExchange(dto),
      id: dto.substanceId,
      type: dto.type,
      compartment: dto.compartment as CompartmentDto,
      subCompartment: dto.subCompartment as SubCompartmentDto,
      displayedContributions: [],
    };
  }

  private mapTechnosphereExchange(
    dto: TechnosphereExchangeDto
  ): TechnoExchange {
    return {
      ...this.mapExchange(dto),
      id: dto.childDatasetId,
      type: dto.type,
      navigable: dto.navigable,
      embeddedProducts: this.embeddedProductMapper.fromList(
        dto.embeddedProducts
      ),
      warnings: dto.warnings.map((w) => w.description),
      displayedContributions: [],
    };
  }
}
