import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ViewBasketComponent } from '@app/modules/basket/pages/view-basket/view-basket.component';
import { AuthGuard } from '@auth0/auth0-angular';

const routes: Routes = [
  {
    path: 'basket',
    component: ViewBasketComponent,
    data: {
      showBasket: true,
      menuButtons: [{ linkTo: '/datasets', label: 'Search LCIA datasets' }],
    },
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class BasketRoutingModule {}
