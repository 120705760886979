<table class="simple-table">
  <tbody
    exchangeTableSection
    title="Reference product"
    [type]="SectionType.REF_PRODUCT"
    [exchanges]="[dataset.exchanges.referenceProduct]"
    [methodIndicator]="methodIndicator"
    [contributionType]="contributionType"
  ></tbody>
  <tbody
    exchangeRefProduct
    [dataset]="dataset"
    [methodIndicator]="methodIndicator"
    [contributionType]="contributionType"
    [totalDirectContribution]="totalDirectContribution"
    [showPercentages]="showPercentages"
    [expandedCommentId]="expandedCommentId"
    (expandedCommentIdChange)="onExpandedCommentIdChange($event)"
  ></tbody>
  <tbody
    *ngIf="hasCoProducts"
    exchangeCoProducts
    [coProducts]="dataset.coProducts"
    [methodIndicator]="methodIndicator"
    [contributionType]="contributionType"
    [expandedCommentId]="expandedCommentId"
    (expandedCommentIdChange)="onExpandedCommentIdChange($event)"
  ></tbody>
  <ng-container *ngIf="hasFromTechnosphere">
    <tbody
      exchangeTableSection
      title="From technosphere"
      [type]="SectionType.TECHNOSPHERE"
      [exchanges]="dataset.exchanges.fromTechnosphere"
      [methodIndicator]="methodIndicator"
      [contributionType]="contributionType"
    ></tbody>
    <tbody
      exchangeTechnosphere
      [exchanges]="dataset.exchanges.fromTechnosphere"
      [totalDirectContribution]="totalDirectContribution"
      [methodIndicator]="methodIndicator"
      [contributionType]="contributionType"
      [showPercentages]="showPercentages"
      [expandedCommentId]="expandedCommentId"
      (expandedCommentIdChange)="onExpandedCommentIdChange($event)"
    ></tbody>
    <tbody
      *ngIf="showContribution"
      exchangeSubtotal
      title="Technosphere contribution sub-total"
      [contributions]="fromTechnosphereContributions"
      [totalDirectContribution]="totalDirectContribution"
      [methodIndicator]="methodIndicator!"
      [contributionType]="contributionType!"
      [showPercentages]="showPercentages"
    ></tbody>
  </ng-container>
  <ng-container *ngIf="hasToTechnosphere">
    <tbody
      exchangeTableSection
      title="To technosphere"
      [type]="SectionType.TECHNOSPHERE"
      [exchanges]="dataset.exchanges.toTechnosphere"
      [methodIndicator]="methodIndicator"
      [contributionType]="contributionType"
    ></tbody>
    <tbody
      exchangeTechnosphere
      [exchanges]="dataset.exchanges.toTechnosphere"
      [totalDirectContribution]="totalDirectContribution"
      [methodIndicator]="methodIndicator"
      [contributionType]="contributionType"
      [showPercentages]="showPercentages"
      [expandedCommentId]="expandedCommentId"
      (expandedCommentIdChange)="onExpandedCommentIdChange($event)"
    ></tbody>
  </ng-container>
  <ng-container *ngIf="hasFromBiosphere && !showSplitGasContribution">
    <tbody
      exchangeTableSection
      title="From biosphere"
      [type]="SectionType.BIOSPHERE"
      [exchanges]="dataset.exchanges.fromBiosphere"
      [methodIndicator]="methodIndicator"
      [contributionType]="contributionType"
    ></tbody>
    <tbody
      exchangeBiosphere
      [exchanges]="dataset.exchanges.fromBiosphere"
      [totalDirectContribution]="totalDirectContribution"
      [methodIndicator]="methodIndicator"
      [contributionType]="contributionType"
      [showPercentages]="showPercentages"
      [expandedCommentId]="expandedCommentId"
      (expandedCommentIdChange)="onExpandedCommentIdChange($event)"
    ></tbody>
    <tbody
      *ngIf="showContribution"
      exchangeSubtotal
      title="From biosphere contribution sub-total"
      [contributions]="fromBiosphereContributions"
      [totalDirectContribution]="totalDirectContribution"
      [methodIndicator]="methodIndicator!"
      [contributionType]="contributionType!"
      [showPercentages]="showPercentages"
    ></tbody>
  </ng-container>
  <ng-container *ngIf="hasToBiosphere">
    <tbody
      exchangeTableSection
      title="To biosphere"
      [type]="SectionType.BIOSPHERE"
      [exchanges]="dataset.exchanges.toBiosphere"
      [methodIndicator]="methodIndicator"
      [contributionType]="contributionType"
    ></tbody>
    <tbody
      *ngIf="!showSplitGasContribution"
      exchangeBiosphere
      [exchanges]="dataset.exchanges.toBiosphere"
      [totalDirectContribution]="totalDirectContribution"
      [methodIndicator]="methodIndicator"
      [contributionType]="contributionType"
      [showPercentages]="showPercentages"
      [expandedCommentId]="expandedCommentId"
      (expandedCommentIdChange)="onExpandedCommentIdChange($event)"
    ></tbody>
    <tbody
      *ngIf="showContribution"
      exchangeSubtotal
      title="To biosphere contribution sub-total"
      [contributions]="toBiosphereContributions"
      [totalDirectContribution]="totalDirectContribution"
      [methodIndicator]="methodIndicator!"
      [contributionType]="contributionType!"
      [showPercentages]="showPercentages"
    ></tbody>
  </ng-container>
</table>
