import { Injectable } from '@angular/core';
import { MethodIndicator } from '@app/modules/reference-data/modules/method/models/method.model';
import {
  Contribution,
  contributionsTreeLeaves,
  ContributionTypeName
} from "@app/modules/dataset/modules/contribution/model/contribution.model";

@Injectable({
  providedIn: 'root',
})
export class ContributionService {
  findContributionTypes(
    contributions: Contribution[],
    indicator: MethodIndicator
  ): ContributionTypeName[] {
    return [
      ...new Set(
        contributions
          .filter((c) => c.indicator.id === indicator.id)
          .map((c) => c.type)
      ),
    ];
  }

  findContribution(
    contributions: Contribution[],
    indicator: MethodIndicator,
    type: ContributionTypeName,
    name: string
  ): Contribution | undefined {
    contributions = this.findContributions(contributions, indicator, { name });
    return contributions.length > 0 ? contributions[0] : undefined;
  }

  findContributions(
    contributions: Contribution[],
    indicator: MethodIndicator,
    options: {
      type?: ContributionTypeName;
      name?: string;
    } = {}
  ): Contribution[] {
    contributions = contributions.filter(
      (c) => c.indicator.id === indicator.id
    );
    if (options.type !== undefined) {
      contributions = contributions.filter((c) => c.type === options.type);
    }
    if (options.name !== undefined) {
      contributions = contributions.filter((c) => c.name === options.name);
    }
    return contributions;
  }

  totalContributions(
    contributions: Contribution[],
    indicator: MethodIndicator,
    type: ContributionTypeName
  ): Array<Contribution> {
    return (contributionsTreeLeaves(type) ?? []).map((contributionNode) => {
      const foundContributions = this.findContributions(
        contributions,
        indicator,
        { type, name: contributionNode.name }
      );
      return {
        ...contributionNode,
        indicator,
        amount: this.sumContributions(foundContributions),
      };
    });
  }

  private sumContributions(contributions: Contribution[]): number | undefined {
    if (contributions.length === 0) {
      return undefined;
    }
    return contributions
      .map((c) => c.amount)
      .reduce(
        (a, b) => (a !== undefined && b !== undefined ? a + b : undefined),
        0
      );
  }

  totalSplitGasContributionToBiosphere(
    refProductContributions: Array<Contribution>,
    fromTechnosphereContributions: Array<Contribution>,
    methodIndicator: MethodIndicator
  ): Array<Contribution> {
    const contributionType = 'SPLIT_GAS';
    const totalRefProductSplitGasContributions = this.totalContributions(
      refProductContributions,
      methodIndicator,
      contributionType
    );
    const totalFromTechnosphereSplitGasContributions = this.totalContributions(
      fromTechnosphereContributions,
      methodIndicator,
      contributionType
    );
    return (contributionsTreeLeaves(contributionType) ?? []).map((_, i) => {
      const total = totalRefProductSplitGasContributions[i];
      const technosphere = totalFromTechnosphereSplitGasContributions[i];
      return {
        ...total,
        amount: this.totalContributionToBiosphere(
          total.amount,
          technosphere.amount
        ),
      };
    });
  }

  private totalContributionToBiosphere(
    total: number | undefined,
    technosphere: number | undefined
  ): number | undefined {
    return total !== undefined && technosphere !== undefined
      ? total - technosphere
      : undefined;
  }
}
