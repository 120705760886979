/**
 * Exchange table structure:
 *
 * <table>
 *   <tr>
 *     <th>Name</th>
 *     <th>Compartment</th>
 *     <th>Sub-compartment / Allocation</th>
 *     <th>Amount</th>
 *     <th>Unit</th>
 *     <th>Contribution 1</th>
 *     <th>Contribution 2</th>
 *     <th>...</th>
 *     <th>Contribution n</th>
 *     <th>Comment toggle</th>
 *     <th>Warning</th>
 *   </tr>
 * </table>
 */
export const NB_COLUMNS_EXCLUDING_CONTRIBUTION = 7;
