<tr>
  <td>{{ title }}</td>
  <td colspan="4">&nbsp;</td>
  <td
    *ngFor="let totalContribution of totalContributions"
    [ngClass]="[
      'contribution',
      totalContribution.type | slugify,
      totalContribution.name | slugify
    ]"
  >
    <mat-divider class="subtotal-bar"></mat-divider>
    <app-contribution-value
      [amount]="totalContribution.amount"
      [referenceAmount]="totalDirectContribution"
      [showPercentages]="showPercentages"
    ></app-contribution-value>
  </td>
  <td colspan="2"></td>
</tr>
