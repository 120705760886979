import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UiModule } from '@app/modules/ui/ui.module';
import { UnitModule } from '@app/modules/reference-data/modules/unit/unit.module';
import { SankeyModule } from '@app/modules/dataset/modules/exchange/modules/sankey/sankey.module';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ContributionAnalysisRowComponent } from '@app/modules/dataset/modules/embedded-product/components/contribution-analysis-row/contribution-analysis-row.component';
import { MaterialModule } from '@app/modules/material/material.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { EmbeddedProductTabComponent } from './components/embedded-product-tab/embedded-product-tab.component';
import { ExchangeModule } from '@app/modules/dataset/modules/exchange/exchange.module';

@NgModule({
  declarations: [EmbeddedProductTabComponent, ContributionAnalysisRowComponent],
  imports: [
    CommonModule,
    RouterModule,
    UiModule,
    UnitModule,
    SankeyModule,
    MatDividerModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MaterialModule,
    ExchangeModule,
  ],
  exports: [EmbeddedProductTabComponent],
})
export class EmbeddedProductModule {}
