<tr>
  <td colspan="5">
    <h2>{{ title }}</h2>
  </td>
  <td
    *ngIf="contributionColumns.length > 0"
    [attr.colspan]="contributionColumns.length"
  ></td>
  <td colspan="2"></td>
</tr>
<ng-container [ngSwitch]="type">
  <tr *ngSwitchCase="SectionType.TECHNOSPHERE">
    <th colspan="3"></th>
    <th>Amount</th>
    <th>Unit</th>
    <th
      *ngFor="let contribution of contributionColumns"
      [ngClass]="[
        'contribution',
        'header',
        contribution.type | slugify,
        contribution.name | slugify
      ]"
    >
      {{ contribution.displayName }}
    </th>
    <td colspan="2"></td>
  </tr>
  <tr *ngSwitchCase="SectionType.BIOSPHERE">
    <th></th>
    <ng-container *ngIf="contributionType !== SPLIT_GAS; else noHeaders">
      <th>Compartment</th>
      <th>Subcompartment</th>
      <th>Amount</th>
      <th>Unit</th>
    </ng-container>
    <ng-template #noHeaders>
      <th colspan="4"></th>
    </ng-template>
    <th
      *ngFor="let contribution of contributionColumns"
      [ngClass]="[
        'contribution',
        'header',
        contribution.type | slugify,
        contribution.name | slugify
      ]"
    >
      {{ contribution.displayName }}
    </th>
    <td colspan="2"></td>
  </tr>
  <ng-container *ngSwitchCase="SectionType.REF_PRODUCT">
    <tr>
      <th colspan="2"></th>
      <th>Allocation</th>
      <th>Amount</th>
      <th>Unit</th>
      <th
        *ngIf="contributionColumns.length > 0"
        [attr.colspan]="contributionColumns.length"
        class="contribution direct header"
      >
        Total
      </th>
      <td colspan="2"></td>
    </tr>
  </ng-container>
</ng-container>
